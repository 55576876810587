"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _marketing = require("@/api/marketing");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "UserDetails",
  props: {
    uid: {
      type: Number,
      default: null
    }
  },
  data: function data() {
    return {
      loading: false,
      columns: [],
      Visible: false,
      list: [{
        val: '0',
        label: '消费记录'
      }, {
        val: '1',
        label: '积分明细'
      }, {
        val: '2',
        label: '签到记录'
      }, {
        val: '3',
        label: '持有优惠券'
      }, {
        val: '4',
        label: '余额变动'
      }, {
        val: '5',
        label: '好友关系'
      }],
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 6,
        type: '0',
        userId: ''
      },
      psInfo: null
    };
  },
  mounted: function mounted() {
    if (this.uid) {
      this.getHeader();
      this.getInfo();
    }
  },
  methods: {
    changeType: function changeType(key) {
      this.tableFrom.type = key;
      if (key === '1') {
        this.integral();
      } else {
        this.getInfo();
      }
    },
    integral: function integral() {
      var _this = this;
      this.loading = true;
      (0, _marketing.integralListApi)({
        limit: this.tableFrom.limit,
        page: this.tableFrom.page
      }, {
        uid: this.uid
      }).then(function (res) {
        _this.tableData.data = res.list;
        _this.tableData.total = res.total;
        _this.columns = [{
          title: '来源/用途',
          key: 'title',
          minWidth: 120
        }, {
          title: '积分变化',
          key: 'integral',
          minWidth: 120
        }, {
          title: '变化后积分',
          key: 'balance',
          minWidth: 120
        }, {
          title: '日期',
          key: 'updateTime',
          minWidth: 120
        }, {
          title: '备注',
          key: 'mark',
          minWidth: 120
        }];
        _this.loading = false;
      }).catch(function (res) {
        _this.loading = false;
      });
    },
    getInfo: function getInfo() {
      var _this2 = this;
      this.tableFrom.userId = this.uid;
      this.loading = true;
      (0, _user.infobyconditionApi)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.list;
        _this2.tableData.total = res.total;
        switch (_this2.tableFrom.type) {
          case '0':
            _this2.columns = [{
              title: '订单ID',
              key: 'orderId',
              minWidth: 250
            }, {
              title: '收货人',
              key: 'realName',
              minWidth: 90
            }, {
              title: '商品数量',
              key: 'totalNum',
              minWidth: 80
            }, {
              title: '商品总价',
              key: 'totalPrice',
              minWidth: 90
            }, {
              title: '实付金额',
              key: 'payPrice',
              minWidth: 90
            }, {
              title: '交易完成时间',
              key: 'payTime',
              minWidth: 160
            }];
            break;
          case '2':
            _this2.columns = [{
              title: '动作',
              key: 'title',
              minWidth: 120
            }, {
              title: '获得积分',
              key: 'number',
              minWidth: 120
            }, {
              title: '签到时间',
              key: 'createTime',
              minWidth: 120
            }, {
              title: '备注',
              key: 'title',
              minWidth: 120
            }];
            break;
          case '3':
            _this2.columns = [{
              title: '优惠券名称',
              key: 'name',
              minWidth: 120
            }, {
              title: '面值',
              key: 'money',
              minWidth: 120
            }, {
              title: '有效期',
              key: 'endTime',
              minWidth: 120
            }, {
              title: '最低消费额',
              key: 'minPrice',
              minWidth: 120
            }, {
              title: '兑换时间',
              key: 'updateTime',
              minWidth: 120
            }];
            break;
          case '4':
            _this2.columns = [{
              title: '变动金额',
              key: 'number',
              minWidth: 120
            }, {
              title: '变动后',
              key: 'balance',
              minWidth: 120
            }, {
              title: '类型',
              key: 'title',
              minWidth: 120
            }, {
              title: '创建时间',
              key: 'add_time',
              minWidth: 120
            }, {
              title: '备注',
              key: 'mark',
              minWidth: 120
            }];
            break;
          default:
            _this2.columns = [{
              title: 'ID',
              key: 'uid',
              minWidth: 120
            }, {
              title: '昵称',
              key: 'nickname',
              minWidth: 120
            }, {
              title: '等级',
              key: 'level',
              minWidth: 120
            }, {
              title: '加入时间',
              key: 'createTime',
              minWidth: 120
            }];
        }
        _this2.loading = false;
      }).catch(function () {
        _this2.loading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      if (this.tableFrom.type === '1') {
        this.integral();
      } else {
        this.getInfo();
      }
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      if (this.tableFrom.type === '1') {
        this.integral();
      } else {
        this.getInfo();
      }
    },
    getHeader: function getHeader() {
      var _this3 = this;
      (0, _user.topdetailApi)({
        userId: this.uid
      }).then(function (res) {
        _this3.psInfo = res;
      });
    }
  }
};