var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "editPram",
          attrs: { model: _vm.editPram, "label-width": "130px" },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "分类名称",
                prop: "name",
                rules: [
                  {
                    required: true,
                    message: "请输入分类名称",
                    trigger: ["blur", "change"],
                  },
                ],
              },
            },
            [
              _c("el-input", {
                attrs: {
                  maxlength: _vm.biztype.value === 1 ? 8 : 20,
                  placeholder: "分类名称",
                },
                model: {
                  value: _vm.editPram.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.editPram, "name", $$v)
                  },
                  expression: "editPram.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.biztype.value !== 1 && _vm.biztype.value !== 3
            ? _c(
                "el-form-item",
                { attrs: { label: "URL" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "URL" },
                    model: {
                      value: _vm.editPram.url,
                      callback: function ($$v) {
                        _vm.$set(_vm.editPram, "url", $$v)
                      },
                      expression: "editPram.url",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.biztype.value !== 3
            ? _c(
                "el-form-item",
                { attrs: { label: "父级" } },
                [
                  _c("el-cascader", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      disabled: _vm.isCreate === 1 && _vm.editPram.pid === 0,
                      clearable: "",
                      options:
                        _vm.biztype.value === 5
                          ? _vm.allTreeList
                          : _vm.parentOptions,
                      props: _vm.categoryProps,
                    },
                    model: {
                      value: _vm.editPram.pid,
                      callback: function ($$v) {
                        _vm.$set(_vm.editPram, "pid", $$v)
                      },
                      expression: "editPram.pid",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.biztype.value === 5
            ? _c(
                "el-form-item",
                { attrs: { label: "菜单图标" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { placeholder: "请选择菜单图标" },
                      model: {
                        value: _vm.editPram.extra,
                        callback: function ($$v) {
                          _vm.$set(_vm.editPram, "extra", $$v)
                        },
                        expression: "editPram.extra",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: {
                          slot: "append",
                          icon: "el-icon-circle-plus-outline",
                        },
                        on: { click: _vm.addIcon },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.biztype.value === 1 || _vm.biztype.value === 3
            ? _c("el-form-item", { attrs: { label: "分类图标(180*180)" } }, [
                _c(
                  "div",
                  {
                    staticClass: "upLoadPicBox",
                    on: {
                      click: function ($event) {
                        return _vm.modalPicTap("1")
                      },
                    },
                  },
                  [
                    _vm.editPram.extra
                      ? _c("div", { staticClass: "pictrue" }, [
                          _c("img", { attrs: { src: _vm.editPram.extra } }),
                        ])
                      : _c("div", { staticClass: "upLoad" }, [
                          _c("i", {
                            staticClass: "el-icon-camera cameraIconfont",
                          }),
                        ]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "排序" } },
            [
              _c("el-input-number", {
                attrs: { min: 0 },
                model: {
                  value: _vm.editPram.sort,
                  callback: function ($$v) {
                    _vm.$set(_vm.editPram, "sort", $$v)
                  },
                  expression: "editPram.sort",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "状态" } },
            [
              _c("el-switch", {
                attrs: {
                  "active-text": "显示",
                  "inactive-text": "隐藏",
                  "active-value": true,
                  "inactive-value": false,
                },
                model: {
                  value: _vm.editPram.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.editPram, "status", $$v)
                  },
                  expression: "editPram.status",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.biztype.value !== 1 &&
          _vm.biztype.value !== 3 &&
          _vm.biztype.value !== 5
            ? _c(
                "el-form-item",
                { attrs: { label: "扩展字段" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", placeholder: "扩展字段" },
                    model: {
                      value: _vm.editPram.extra,
                      callback: function ($$v) {
                        _vm.$set(_vm.editPram, "extra", $$v)
                      },
                      expression: "editPram.extra",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["admin:category:update"],
                      expression: "['admin:category:update']",
                    },
                  ],
                  attrs: { type: "primary", loading: _vm.loadingBtn },
                  on: {
                    click: function ($event) {
                      return _vm.handlerSubmit("editPram")
                    },
                  },
                },
                [_vm._v("确定")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取消")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }