"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _creatGrade = _interopRequireDefault(require("./creatGrade"));
var _permission = require("@/utils/permission");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'Grade',
  filters: {
    typeFilter: function typeFilter(status) {
      var statusMap = {
        'wechat': '微信用户',
        'routine': '小程序你用户',
        'h5': 'H5用户'
      };
      return statusMap[status];
    }
  },
  components: {
    creatGrade: _creatGrade.default
  },
  data: function data() {
    return {
      listLoading: true,
      userInfo: {},
      tableData: {
        data: [],
        total: 0
      }
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    seachList: function seachList() {
      this.getList();
    },
    add: function add() {
      this.$refs.grades.dialogVisible = true;
      this.userInfo = {};
    },
    edit: function edit(id) {
      // this.$refs.grades.info(id)
      this.userInfo = id;
      this.$refs.grades.dialogVisible = true;
    },
    // 列表
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _user.levelListApi)().then(function (res) {
        _this.tableData.data = res;
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this2 = this;
      this.$modalSure('删除吗？删除会导致对应用户等级数据清空，请谨慎操作！').then(function () {
        (0, _user.levelDeleteApi)(id).then(function () {
          _this2.$message.success('删除成功');
          _this2.tableData.data.splice(idx, 1);
        });
      });
    },
    onchangeIsShow: function onchangeIsShow(row) {
      var _this3 = this;
      if (row.isShow == false) {
        row.isShow = !row.isShow;
        (0, _user.levelUseApi)({
          id: row.id,
          isShow: row.isShow
        }).then(function () {
          _this3.$message.success('修改成功');
          _this3.getList();
        }).catch(function () {
          row.isShow = !row.isShow;
        });
      } else {
        this.$modalSure('该操作会导致对应用户等级隐藏，请谨慎操作').then(function () {
          row.isShow = !row.isShow;
          (0, _user.levelUseApi)({
            id: row.id,
            isShow: row.isShow
          }).then(function () {
            _this3.$message.success('修改成功');
            _this3.getList();
          }).catch(function () {
            row.isShow = !row.isShow;
          });
        });
      }
    }
  }
};