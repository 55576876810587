var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "el-tabs",
                {
                  on: {
                    "tab-click": function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.loginType,
                    callback: function ($$v) {
                      _vm.loginType = $$v
                    },
                    expression: "loginType",
                  },
                },
                _vm._l(_vm.headeNum, function (item, index) {
                  return _c("el-tab-pane", {
                    key: index,
                    attrs: { label: item.name, name: item.type.toString() },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "userFrom",
                      attrs: {
                        inline: "",
                        size: "small",
                        model: _vm.userFrom,
                        "label-position": _vm.labelPosition,
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              attrs: { xs: 24, sm: 24, md: 24, lg: 18, xl: 18 },
                            },
                            [
                              _c(
                                "el-col",
                                _vm._b({}, "el-col", _vm.grid, false),
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "用户搜索：" } },
                                    [
                                      _c("el-input", {
                                        staticClass: "selWidth",
                                        attrs: {
                                          placeholder: "请输入姓名或手机号",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.userFrom.keywords,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.userFrom,
                                              "keywords",
                                              $$v
                                            )
                                          },
                                          expression: "userFrom.keywords",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.collapse
                            ? [
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 24,
                                      md: 24,
                                      lg: 18,
                                      xl: 18,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      _vm._b({}, "el-col", _vm.grid, false),
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "用户等级：" } },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticClass: "selWidth",
                                                attrs: {
                                                  placeholder: "请选择",
                                                  clearable: "",
                                                  filterable: "",
                                                  multiple: "",
                                                },
                                                model: {
                                                  value: _vm.levelData,
                                                  callback: function ($$v) {
                                                    _vm.levelData = $$v
                                                  },
                                                  expression: "levelData",
                                                },
                                              },
                                              _vm._l(
                                                _vm.levelList,
                                                function (item, index) {
                                                  return _c("el-option", {
                                                    key: index,
                                                    attrs: {
                                                      value: item.id,
                                                      label: item.name,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      _vm._b({}, "el-col", _vm.grid, false),
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "用户分组：" } },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticClass: "selWidth",
                                                attrs: {
                                                  placeholder: "请选择",
                                                  clearable: "",
                                                  filterable: "",
                                                  multiple: "",
                                                },
                                                model: {
                                                  value: _vm.groupData,
                                                  callback: function ($$v) {
                                                    _vm.groupData = $$v
                                                  },
                                                  expression: "groupData",
                                                },
                                              },
                                              _vm._l(
                                                _vm.groupList,
                                                function (item, index) {
                                                  return _c("el-option", {
                                                    key: index,
                                                    attrs: {
                                                      value: item.id,
                                                      label: item.groupName,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      _vm._b({}, "el-col", _vm.grid, false),
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "用户标签：" } },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticClass: "selWidth",
                                                attrs: {
                                                  placeholder: "请选择",
                                                  clearable: "",
                                                  filterable: "",
                                                  multiple: "",
                                                },
                                                model: {
                                                  value: _vm.labelData,
                                                  callback: function ($$v) {
                                                    _vm.labelData = $$v
                                                  },
                                                  expression: "labelData",
                                                },
                                              },
                                              _vm._l(
                                                _vm.labelLists,
                                                function (item, index) {
                                                  return _c("el-option", {
                                                    key: index,
                                                    attrs: {
                                                      value: item.id,
                                                      label: item.name,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 24,
                                      md: 24,
                                      lg: 18,
                                      xl: 18,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      _vm._b({}, "el-col", _vm.grid, false),
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "国家：" } },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticClass: "selWidth",
                                                attrs: {
                                                  placeholder: "请选择",
                                                  clearable: "",
                                                },
                                                on: {
                                                  "on-change":
                                                    _vm.changeCountry,
                                                },
                                                model: {
                                                  value: _vm.userFrom.country,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.userFrom,
                                                      "country",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "userFrom.country",
                                                },
                                              },
                                              [
                                                _c("el-option", {
                                                  attrs: {
                                                    value: "",
                                                    label: "全部",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("el-option", {
                                                  attrs: {
                                                    value: "CN",
                                                    label: "中国",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("el-option", {
                                                  attrs: {
                                                    value: "OTHER",
                                                    label: "国外",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.userFrom.country === "CN"
                                      ? _c(
                                          "el-col",
                                          _vm._b({}, "el-col", _vm.grid, false),
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "省份：" } },
                                              [
                                                _c("el-cascader", {
                                                  staticClass: "selWidth",
                                                  attrs: {
                                                    options: _vm.addresData,
                                                    props: _vm.propsCity,
                                                    filterable: "",
                                                    clearable: "",
                                                  },
                                                  on: {
                                                    change: _vm.handleChange,
                                                  },
                                                  model: {
                                                    value: _vm.address,
                                                    callback: function ($$v) {
                                                      _vm.address = $$v
                                                    },
                                                    expression: "address",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      _vm._b({}, "el-col", _vm.grid, false),
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "消费情况：" } },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticClass: "selWidth",
                                                attrs: {
                                                  placeholder: "请选择",
                                                  clearable: "",
                                                },
                                                model: {
                                                  value: _vm.userFrom.payCount,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.userFrom,
                                                      "payCount",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "userFrom.payCount",
                                                },
                                              },
                                              [
                                                _c("el-option", {
                                                  attrs: {
                                                    value: "",
                                                    label: "全部",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("el-option", {
                                                  attrs: {
                                                    value: "0",
                                                    label: "0",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("el-option", {
                                                  attrs: {
                                                    value: "1",
                                                    label: "1+",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("el-option", {
                                                  attrs: {
                                                    value: "2",
                                                    label: "2+",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("el-option", {
                                                  attrs: {
                                                    value: "3",
                                                    label: "3+",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("el-option", {
                                                  attrs: {
                                                    value: "4",
                                                    label: "4+",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("el-option", {
                                                  attrs: {
                                                    value: "5",
                                                    label: "5+",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      _vm._b({}, "el-col", _vm.grid, false),
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticClass: "timeBox",
                                            attrs: { label: "时间选择：" },
                                          },
                                          [
                                            _c("el-date-picker", {
                                              staticClass: "selWidth",
                                              attrs: {
                                                align: "right",
                                                "unlink-panels": "",
                                                "value-format": "yyyy-MM-dd",
                                                format: "yyyy-MM-dd",
                                                size: "small",
                                                type: "daterange",
                                                placement: "bottom-end",
                                                placeholder: "自定义时间",
                                                "picker-options":
                                                  _vm.pickerOptions,
                                              },
                                              on: { change: _vm.onchangeTime },
                                              model: {
                                                value: _vm.timeVal,
                                                callback: function ($$v) {
                                                  _vm.timeVal = $$v
                                                },
                                                expression: "timeVal",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 24,
                                      md: 24,
                                      lg: 18,
                                      xl: 18,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      _vm._b({}, "el-col", _vm.grid, false),
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "访问情况：" } },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticClass: "selWidth",
                                                attrs: {
                                                  placeholder: "请选择",
                                                  clearable: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.userFrom.accessType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.userFrom,
                                                      "accessType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "userFrom.accessType",
                                                },
                                              },
                                              [
                                                _c("el-option", {
                                                  attrs: {
                                                    value: 0,
                                                    label: "全部",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("el-option", {
                                                  attrs: {
                                                    value: 1,
                                                    label: "首次访问",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("el-option", {
                                                  attrs: {
                                                    value: 2,
                                                    label: "时间段访问过",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("el-option", {
                                                  attrs: {
                                                    value: 3,
                                                    label: "时间段未访问",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      _vm._b({}, "el-col", _vm.grid, false),
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "性别：" } },
                                          [
                                            _c(
                                              "el-radio-group",
                                              {
                                                staticClass: "selWidth",
                                                attrs: { type: "button" },
                                                model: {
                                                  value: _vm.userFrom.sex,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.userFrom,
                                                      "sex",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "userFrom.sex",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-radio-button",
                                                  { attrs: { label: "" } },
                                                  [_c("span", [_vm._v("全部")])]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-radio-button",
                                                  { attrs: { label: "0" } },
                                                  [_c("span", [_vm._v("未知")])]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-radio-button",
                                                  { attrs: { label: "1" } },
                                                  [_c("span", [_vm._v("男")])]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-radio-button",
                                                  { attrs: { label: "2" } },
                                                  [_c("span", [_vm._v("女")])]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-radio-button",
                                                  { attrs: { label: "3" } },
                                                  [_c("span", [_vm._v("保密")])]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      _vm._b({}, "el-col", _vm.grid, false),
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "身份：" } },
                                          [
                                            _c(
                                              "el-radio-group",
                                              {
                                                staticClass: "selWidth",
                                                attrs: { type: "button" },
                                                model: {
                                                  value:
                                                    _vm.userFrom.isPromoter,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.userFrom,
                                                      "isPromoter",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "userFrom.isPromoter",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-radio-button",
                                                  { attrs: { label: "" } },
                                                  [_c("span", [_vm._v("全部")])]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-radio-button",
                                                  { attrs: { label: "1" } },
                                                  [
                                                    _c("span", [
                                                      _vm._v("推广员"),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-radio-button",
                                                  { attrs: { label: "0" } },
                                                  [
                                                    _c("span", [
                                                      _vm._v("普通用户"),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticClass: "text-right userFrom",
                              attrs: { xs: 24, sm: 24, md: 24, lg: 6, xl: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "mr15",
                                      attrs: {
                                        type: "primary",
                                        icon: "ios-search",
                                        label: "default",
                                        size: "small",
                                      },
                                      on: { click: _vm.userSearchs },
                                    },
                                    [_vm._v("搜索")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "ResetSearch mr10",
                                      attrs: { size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.reset("userFrom")
                                        },
                                      },
                                    },
                                    [_vm._v("重置")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "ivu-ml-8",
                                      on: {
                                        click: function ($event) {
                                          _vm.collapse = !_vm.collapse
                                        },
                                      },
                                    },
                                    [
                                      !_vm.collapse
                                        ? [
                                            _vm._v(
                                              "\n                    展开 "
                                            ),
                                            _c("i", {
                                              staticClass: "el-icon-arrow-down",
                                            }),
                                          ]
                                        : [
                                            _vm._v(
                                              "\n                    收起 "
                                            ),
                                            _c("i", {
                                              staticClass: "el-icon-arrow-up",
                                            }),
                                          ],
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btn_bt" },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["admin:coupon:user:receive"],
                          expression: "['admin:coupon:user:receive']",
                        },
                      ],
                      staticClass: "mr10",
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.onSend },
                    },
                    [_vm._v("发送优惠券")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "mr10",
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.setBatch("group")
                        },
                      },
                    },
                    [_vm._v("批量设置分组")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "mr10",
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.setBatch("label")
                        },
                      },
                    },
                    [_vm._v("批量设置标签")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "mini",
                "highlight-current-row": "",
              },
              on: { "selection-change": _vm.onSelectTab },
            },
            [
              _c("el-table-column", {
                attrs: { type: "expand" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "el-form",
                          {
                            staticClass: "demo-table-expand",
                            attrs: { "label-position": "left", inline: "" },
                          },
                          [
                            _c("el-form-item", { attrs: { label: "身份：" } }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("filterIsPromoter")(
                                      props.row.isPromoter
                                    )
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "首次访问：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(
                                        props.row.createTime
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "近次访问：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(
                                        props.row.lastLoginTime
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "手机号：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(props.row.phone)
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c("el-form-item", { attrs: { label: "标签：" } }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("filterEmpty")(props.row.tagName)
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("el-form-item", { attrs: { label: "地址：" } }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("filterEmpty")(props.row.addres)
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                staticStyle: {
                                  width: "100%",
                                  display: "flex",
                                  "margin-right": "10px",
                                },
                                attrs: { label: "备注：" },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(props.row.mark)
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "uid", label: "ID", "min-width": "80" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "头像", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              staticStyle: { width: "36px", height: "36px" },
                              attrs: {
                                src: scope.row.avatar,
                                "preview-src-list": [scope.row.avatar],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "姓名", "min-width": "160" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("filterEmpty")(scope.row.nickname)) +
                              " | " +
                              _vm._s(_vm._f("sexFilter")(scope.row.sex))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "用户等级", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("filterEmpty")(
                                _vm._f("levelFilter")(scope.row.level)
                              )
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "groupName", label: "分组", "min-width": "100" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "spreadNickname",
                  label: "推荐人",
                  "min-width": "130",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "手机号", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("filterEmpty")(scope.row.phone))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "nowMoney", label: "余额", "min-width": "100" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "integral", label: "积分", "min-width": "100" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "130",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["admin:user:infobycondition"],
                                expression: "['admin:user:infobycondition']",
                              },
                            ],
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.editUser(scope.row.uid)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-dropdown",
                          { attrs: { trigger: "click" } },
                          [
                            _c("span", { staticClass: "el-dropdown-link" }, [
                              _vm._v("\n              更多"),
                              _c("i", {
                                staticClass:
                                  "el-icon-arrow-down el-icon--right",
                              }),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _vm.checkPermi(["admin:user:topdetail"])
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.onDetails(scope.row.uid)
                                          },
                                        },
                                      },
                                      [_vm._v("账户详情")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkPermi(["admin:user:operate:founds"])
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.editPoint(scope.row.uid)
                                          },
                                        },
                                      },
                                      [_vm._v("积分余额")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkPermi(["admin:user:group"])
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.setBatch(
                                              "group",
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("设置分组")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkPermi(["admin:user:tag"])
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.setBatch(
                                              "label",
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("设置标签")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkPermi(["admin:user:update:phone"])
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.setPhone(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("修改手机号")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkPermi(["admin:user:update:level"])
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.onLevel(
                                              scope.row.uid,
                                              scope.row.level
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("修改用户等级")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkPermi(["admin:user:update:spread"])
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.setExtension(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("修改上级推广人")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.spreadUid &&
                                scope.row.spreadUid > 0 &&
                                _vm.checkPermi(["admin:retail:spread:clean"])
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.clearSpread(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("清除上级推广人")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [15, 30, 45, 60],
                  "page-size": _vm.userFrom.limit,
                  "current-page": _vm.userFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改推广人",
            visible: _vm.extensionVisible,
            width: "500px",
            "before-close": _vm.handleCloseExtension,
          },
          on: {
            "update:visible": function ($event) {
              _vm.extensionVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "formExtension",
              staticClass: "formExtension mt20",
              attrs: {
                model: _vm.formExtension,
                rules: _vm.ruleInline,
                "label-width": "120px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户头像：", prop: "image" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upLoadPicBox",
                      on: { click: _vm.modalPicTap },
                    },
                    [
                      _vm.formExtension.image
                        ? _c("div", { staticClass: "pictrue" }, [
                            _c("img", {
                              attrs: { src: _vm.formExtension.image },
                            }),
                          ])
                        : _c("div", { staticClass: "upLoad" }, [
                            _c("i", {
                              staticClass: "el-icon-camera cameraIconfont",
                            }),
                          ]),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.onSubExtension("formExtension")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "用户列表",
            visible: _vm.userVisible,
            width: "900px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.userVisible = $event
            },
          },
        },
        [
          _vm.userVisible
            ? _c("user-list", { on: { getTemplateRow: _vm.getTemplateRow } })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.userVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.userVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "设置",
            visible: _vm.dialogVisible,
            width: "500px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "dynamicValidateForm",
              staticClass: "demo-dynamic",
              attrs: { model: _vm.dynamicValidateForm, "label-width": "100px" },
            },
            [
              _vm.batchName === "group"
                ? _c(
                    "el-form-item",
                    {
                      key: "1",
                      attrs: {
                        prop: "groupId",
                        label: "用户分组",
                        rules: [
                          {
                            required: true,
                            message: "请选择用户分组",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "80%" },
                          attrs: { placeholder: "请选择分组", filterable: "" },
                          model: {
                            value: _vm.dynamicValidateForm.groupId,
                            callback: function ($$v) {
                              _vm.$set(_vm.dynamicValidateForm, "groupId", $$v)
                            },
                            expression: "dynamicValidateForm.groupId",
                          },
                        },
                        _vm._l(_vm.groupList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { value: item.id, label: item.groupName },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "groupId",
                        label: "用户标签",
                        rules: [
                          {
                            required: true,
                            message: "请选择用户标签",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "80%" },
                          attrs: { placeholder: "请选择标签", filterable: "" },
                          model: {
                            value: _vm.dynamicValidateForm.groupId,
                            callback: function ($$v) {
                              _vm.$set(_vm.dynamicValidateForm, "groupId", $$v)
                            },
                            expression: "dynamicValidateForm.groupId",
                          },
                        },
                        _vm._l(_vm.labelLists, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { value: item.id, label: item.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("dynamicValidateForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "编辑", visible: _vm.visible, width: "600px" },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _vm.visible
            ? _c("edit-from", {
                attrs: { uid: _vm.uid },
                on: { resetForm: _vm.resetForm },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "积分余额",
            visible: _vm.VisiblePoint,
            width: "500px",
            "close-on-click-modal": false,
            "before-close": _vm.handlePointClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.VisiblePoint = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingPoint,
                  expression: "loadingPoint",
                },
              ],
              ref: "PointValidateForm",
              staticClass: "demo-dynamic",
              attrs: { model: _vm.PointValidateForm, "label-width": "100px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "修改余额", required: "" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.PointValidateForm.moneyType,
                        callback: function ($$v) {
                          _vm.$set(_vm.PointValidateForm, "moneyType", $$v)
                        },
                        expression: "PointValidateForm.moneyType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("增加")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("减少")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "余额", required: "" } },
                [
                  _c("el-input-number", {
                    attrs: {
                      type: "text",
                      precision: 2,
                      step: 0.1,
                      min: 0,
                      max: 999999,
                    },
                    model: {
                      value: _vm.PointValidateForm.moneyValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.PointValidateForm, "moneyValue", $$v)
                      },
                      expression: "PointValidateForm.moneyValue",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "修改积分", required: "" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.PointValidateForm.integralType,
                        callback: function ($$v) {
                          _vm.$set(_vm.PointValidateForm, "integralType", $$v)
                        },
                        expression: "PointValidateForm.integralType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("增加")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("减少")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "积分", required: "" } },
                [
                  _c("el-input-number", {
                    attrs: {
                      type: "text",
                      "step-strictly": "",
                      min: 0,
                      max: 999999,
                    },
                    model: {
                      value: _vm.PointValidateForm.integralValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.PointValidateForm, "integralValue", $$v)
                      },
                      expression: "PointValidateForm.integralValue",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handlePointClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingBtn },
                  on: {
                    click: function ($event) {
                      return _vm.submitPointForm("PointValidateForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.uid
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "用户详情",
                visible: _vm.Visible,
                width: "1100px",
                "before-close": _vm.Close,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.Visible = $event
                },
              },
            },
            [
              _vm.Visible
                ? _c("user-details", {
                    ref: "userDetails",
                    attrs: { uid: _vm.uid },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "设置",
            visible: _vm.levelVisible,
            width: "600px",
            "before-close": _vm.Close,
          },
          on: {
            "update:visible": function ($event) {
              _vm.levelVisible = $event
            },
          },
        },
        [
          _c("level-edit", {
            attrs: { levelInfo: _vm.levelInfo, levelList: _vm.levelList },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }