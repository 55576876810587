import { render, staticRenderFns } from "./index.vue?vue&type=template&id=15f24157&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=15f24157&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15f24157",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\Projects\\商城\\admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('15f24157')) {
      api.createRecord('15f24157', component.options)
    } else {
      api.reload('15f24157', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=15f24157&scoped=true", function () {
      api.rerender('15f24157', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/marketing/coupon/list/index.vue"
export default component.exports