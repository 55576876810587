import { render, staticRenderFns } from "./orderDetail.vue?vue&type=template&id=433128ee&scoped=true"
import script from "./orderDetail.vue?vue&type=script&lang=js"
export * from "./orderDetail.vue?vue&type=script&lang=js"
import style0 from "./orderDetail.vue?vue&type=style&index=0&id=433128ee&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "433128ee",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\Projects\\商城\\admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('433128ee')) {
      api.createRecord('433128ee', component.options)
    } else {
      api.reload('433128ee', component.options)
    }
    module.hot.accept("./orderDetail.vue?vue&type=template&id=433128ee&scoped=true", function () {
      api.rerender('433128ee', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/mobile/orderStatistics/orderDetail.vue"
export default component.exports