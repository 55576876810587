"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _validate = require("@/utils/validate");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var obj = {
  name: '',
  grade: 1,
  discount: '',
  icon: '',
  image: '',
  id: null
};
var _default = exports.default = {
  name: "CreatGrade",
  props: {
    'user': Object
  },
  data: function data() {
    return {
      dialogVisible: false,
      formValidate: Object.assign({}, obj),
      loading: false,
      rules: {
        name: [{
          required: true,
          message: '请输入等级名称',
          trigger: 'blur'
        }],
        grade: [{
          required: true,
          message: '请输入等级',
          trigger: 'blur'
        }, {
          type: 'number',
          message: '等级必须为数字值'
        }],
        discount: [{
          required: true,
          message: '请输入折扣',
          trigger: 'blur'
        }],
        experience: [{
          required: true,
          message: '请输入经验',
          trigger: 'blur'
        }, {
          type: 'number',
          message: '经验必须为数字值'
        }],
        icon: [{
          required: true,
          message: '请上传图标',
          trigger: 'change'
        }],
        image: [{
          required: true,
          message: '请上传用户背景',
          trigger: 'change'
        }]
      }
    };
  },
  methods: {
    // 点击商品图
    modalPicTap: function modalPicTap(tit, num) {
      var _this = this;
      this.$modalUpload(function (img) {
        tit === '1' && num === 'icon' ? _this.formValidate.icon = img[0].sattDir : _this.formValidate.image = img[0].sattDir;
        this.$set(_this.user, 'icon', _this.formValidate.icon);
        this.$set(_this.user, 'isShow', false);
      }, tit, 'user');
    },
    info: function info(id) {
      var _this2 = this;
      this.loading = true;
      (0, _user.levelInfoApi)({
        id: id
      }).then(function (res) {
        _this2.formValidate = res;
        _this2.loading = false;
      }).catch(function () {
        _this2.loading = false;
      });
    },
    handleClose: function handleClose() {
      var _this3 = this;
      this.$nextTick(function () {
        _this3.$refs.user.resetFields();
      });
      this.dialogVisible = false;
      // this.user = Object.assign({}, '')
    },
    submitForm: (0, _validate.Debounce)(function (formName) {
      var _this4 = this;
      this.$refs.user.validate(function (valid) {
        if (valid) {
          _this4.loading = true;
          var data = {
            discount: _this4.user.discount,
            experience: _this4.user.experience,
            grade: _this4.user.grade,
            icon: _this4.user.icon,
            id: _this4.user.id,
            isShow: _this4.user.isShow,
            name: _this4.user.name
          };
          _this4.user.id ? (0, _user.levelUpdateApi)(_this4.user.id, data).then(function (res) {
            _this4.$message.success('编辑成功');
            _this4.loading = false;
            _this4.handleClose();
            _this4.formValidate = Object.assign({}, obj);
            _this4.$parent.getList();
          }).catch(function () {
            _this4.loading = false;
          }) : (0, _user.levelSaveApi)(_this4.user).then(function (res) {
            _this4.$message.success('添加成功');
            _this4.loading = false;
            _this4.handleClose();
            _this4.formValidate = Object.assign({}, obj);
            _this4.$parent.getList();
          }).catch(function () {
            _this4.loading = false;
            _this4.formValidate = Object.assign({}, obj);
          });
        } else {
          return false;
        }
      });
    }),
    resetForm: function resetForm(formName) {
      var _this5 = this;
      // this[formName] = {};
      this.$nextTick(function () {
        _this5.$refs.user.resetFields();
      });
      this.dialogVisible = false;
    }
  }
};