//
//
//
//
//
//

export default {
  name: 'EditorSlideUpload',
  props: {
    color: {
      type: String,
      default: '#1890ff'
    },
    maxSize: {
      type: Number,
      default: 10
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      listObj: {},
      fileList: []
    };
  },
  methods: {
    modalPicTap: function modalPicTap(tit) {
      var _this = this;
      this.$modalUpload(function (img) {
        var arr = [];
        if (img.length > _this.maxSize) return this.$message.warning("\u6700\u591A\u9009\u62E9".concat(_this.maxSize, "\u5F20\u56FE\u7247\uFF01"));
        img.map(function (item) {
          arr.push(item.sattDir);
        });
        // console.log(arr);
        _this.$emit('successCBK', arr);
      }, tit, 'content');
    }
  }
};