//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as categoryApi from '@/api/categoryApi.js';
import info from './info';
import edit from './edit';
import * as selfUtil from '@/utils/ZBKJIutil.js';
import { checkPermi, checkRole } from "@/utils/permission";
export default {
  // name: "list"
  components: {
    info: info,
    edit: edit
  },
  props: {
    biztype: {
      // 类型，1 产品分类，2 附件分类，3 文章分类， 4 设置分类， 5 菜单分类
      type: Object,
      default: {
        value: -1
      },
      validator: function validator(obj) {
        return obj.value > 0;
      }
    },
    pid: {
      type: Number,
      default: 0,
      validator: function validator(value) {
        return value >= 0;
      }
    },
    selectModel: {
      // 是否选择模式
      type: Boolean,
      default: false
    },
    selectModelKeys: {
      type: Array
    },
    rowSelect: {}
  },
  data: function data() {
    return {
      selectModelKeysNew: this.selectModelKeys,
      loading: false,
      constants: this.$constants,
      treeProps: {
        label: 'name',
        children: 'child'
        // expandTrigger: 'hover',
        // checkStrictly: false,
        // emitPath: false
      },
      // treeCheckedKeys:[],// 选择模式下的属性结构默认选中
      multipleSelection: [],
      editDialogConfig: {
        visible: false,
        isCreate: 0,
        // 0=创建，1=编辑
        prent: {},
        // 父级对象
        data: {},
        biztype: this.biztype // 统一主业务中的目录类型
      },
      dataList: [],
      treeList: [],
      listPram: {
        pid: this.pid,
        type: this.biztype.value,
        status: -1,
        name: '',
        page: this.$constants.page.page,
        limit: this.$constants.page.limit[0]
      },
      viewInfoConfig: {
        data: null,
        visible: false
      },
      defaultImg: require('@/assets/imgs/moren.jpg')
    };
  },
  mounted: function mounted() {
    /* if(this.biztype.value === 3){
       this.listPram.pageSize = constants.page.pageSize[4]
       this.handlerGetList()
     }else{*/
    this.handlerGetTreeList();
    // }
  },
  methods: {
    checkPermi: checkPermi,
    //权限控制
    onchangeIsShow: function onchangeIsShow(row) {
      var _this = this;
      categoryApi.categroyUpdateStatus(row.id).then(function () {
        _this.$message.success('修改成功');
        _this.handlerGetTreeList();
      }).catch(function () {
        row.status = !row.status;
      });
    },
    handleEditMenu: function handleEditMenu(rowData) {
      this.editDialogConfig.isCreate = 1;
      this.editDialogConfig.data = rowData;
      this.editDialogConfig.prent = rowData;
      this.editDialogConfig.visible = true;
    },
    handleAddMenu: function handleAddMenu(rowData) {
      this.editDialogConfig.isCreate = 0;
      this.editDialogConfig.prent = rowData;
      this.editDialogConfig.data = {};
      this.editDialogConfig.biztype = this.biztype;
      this.editDialogConfig.visible = true;
    },
    getCurrentNode: function getCurrentNode(data) {
      var node = this.$refs.tree.getNode(data);
      this.childNodes(node);
      // this.parentNodes(node);
      //是否编辑的表示
      // this.ruleForm.isEditorFlag = true;
      //编辑时候使用
      this.$emit('rulesSelect', this.$refs.tree.getCheckedKeys());
      // this.selectModelKeys = this.$refs.tree.getCheckedKeys();
      //无论编辑和新增点击了就传到后台这个值
      // this.$emit('rulesSelect', this.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedKeys()));
      // this.ruleForm.menuIdsisEditor = this.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedKeys());
    },
    //具体方法可以看element官网api
    childNodes: function childNodes(node) {
      var len = node.childNodes.length;
      for (var i = 0; i < len; i++) {
        node.childNodes[i].checked = node.checked;
        this.childNodes(node.childNodes[i]);
      }
    },
    parentNodes: function parentNodes(node) {
      if (node.parent) {
        for (var key in node) {
          if (key == "parent") {
            node[key].checked = true;
            this.parentNodes(node[key]);
          }
        }
      }
    },
    handleDelMenu: function handleDelMenu(rowData) {
      var _this2 = this;
      this.$confirm('确定删除当前数据?').then(function () {
        categoryApi.deleteCategroy(rowData).then(function (data) {
          _this2.handlerGetTreeList();
          _this2.$message.success('删除成功');
        });
      });
    },
    handlerGetList: function handlerGetList() {
      this.handlerGetTreeList();
      // categoryApi.listCategroy({status:this.listPram.status, type: 1 }).then(data => {
      //   this.treeList = data.list
      // })
    },
    handlerGetTreeList: function handlerGetTreeList() {
      var _this3 = this;
      // this.biztype.value === 5 && !this.selectModel) ?  -1 : 1
      // const _pram = { type: this.biztype.value, status: !this.selectModel ? -1 : (this.biztype.value === 5 ? -1 : 1) }
      var _pram = {
        type: this.biztype.value,
        status: this.listPram.status,
        name: this.listPram.name
      };
      this.loading = true;
      this.biztype.value !== 3 ? categoryApi.treeCategroy(_pram).then(function (data) {
        _this3.treeList = _this3.handleAddArrt(data);
        _this3.loading = false;
      }).catch(function () {
        _this3.loading = false;
      }) : categoryApi.listCategroy({
        type: 3,
        status: this.listPram.status,
        pid: this.listPram.pid,
        name: this.listPram.name
      }).then(function (data) {
        _this3.treeList = data.list;
      });
    },
    handlerGetInfo: function handlerGetInfo(id) {
      this.viewInfoConfig.data = id;
      this.viewInfoConfig.visible = true;
    },
    handleNodeClick: function handleNodeClick(data) {
      console.log('data:', data);
    },
    handleAddArrt: function handleAddArrt(treeData) {
      var _result = selfUtil.addTreeListLabel(treeData);
      return _result;
    },
    hideEditDialog: function hideEditDialog() {
      var _this4 = this;
      setTimeout(function () {
        _this4.editDialogConfig.prent = {};
        _this4.editDialogConfig.type = 0;
        _this4.editDialogConfig.visible = false;
        _this4.handlerGetTreeList();
      }, 200);
    },
    handleSelectionChange: function handleSelectionChange(d1, _ref) {
      var checkedNodes = _ref.checkedNodes,
        checkedKeys = _ref.checkedKeys,
        halfCheckedNodes = _ref.halfCheckedNodes,
        halfCheckedKeys = _ref.halfCheckedKeys;
      // this.multipleSelection =  checkedKeys.concat(halfCheckedKeys)
      this.multipleSelection = checkedKeys;
      this.$emit('rulesSelect', this.multipleSelection);
    }
  }
};