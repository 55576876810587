"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/components/Tinymce/index"));
var categoryApi = _interopRequireWildcard(require("@/api/categoryApi.js"));
var articleApi = _interopRequireWildcard(require("@/api/article.js"));
var selfUtil = _interopRequireWildcard(require("@/utils/ZBKJIutil.js"));
var _systemSetting = require("@/api/systemSetting");
var _auth = require("@/utils/auth");
var _validate = require("@/utils/validate");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  // name: "edit",
  components: {
    Tinymce: _index.default
  },
  data: function data() {
    return {
      loading: false,
      constants: this.$constants,
      categoryTreeData: [],
      categoryProps: {
        value: 'id',
        label: 'name',
        children: 'child',
        expandTrigger: 'hover',
        checkStrictly: true,
        emitPath: false
      },
      pram: {
        author: null,
        cid: null,
        content: '',
        //<span>My Document\'s Title</span>
        imageInput: '',
        isBanner: false,
        isHot: null,
        shareSynopsis: null,
        shareTitle: null,
        sort: 0,
        synopsis: null,
        title: null,
        url: null,
        id: null
        // mediaId: null
      },
      editData: {},
      myHeaders: {
        'X-Token': (0, _auth.getToken)()
      },
      editorContentLaebl: ""
      // basicForm:{editorContent:""}
    };
  },
  created: function created() {
    this.tempRoute = Object.assign({}, this.$route);
  },
  mounted: function mounted() {
    if (this.$route.params.id) {
      this.getInfo();
      this.setTagsViewTitle();
    }
    this.handlerGetCategoryTreeData();
  },
  methods: {
    getInfo: function getInfo() {
      var _this2 = this;
      categoryApi.articleInfoApi({
        id: this.$route.params.id
      }).then(function (data) {
        _this2.editData = data;
        _this2.hadlerInitEditData();
      });
    },
    modalPicTap: function modalPicTap(tit) {
      var _this = this;
      this.$modalUpload(function (img) {
        _this.pram.imageInput = img[0].sattDir;
      }, tit, 'content');
    },
    hadlerInitEditData: function hadlerInitEditData() {
      if (!this.$route.params.id) return;
      var _this$editData = this.editData,
        author = _this$editData.author,
        cid = _this$editData.cid,
        content = _this$editData.content,
        imageInput = _this$editData.imageInput,
        isBanner = _this$editData.isBanner,
        isHot = _this$editData.isHot,
        shareSynopsis = _this$editData.shareSynopsis,
        shareTitle = _this$editData.shareTitle,
        sort = _this$editData.sort,
        synopsis = _this$editData.synopsis,
        title = _this$editData.title,
        url = _this$editData.url,
        id = _this$editData.id;
      this.pram.author = author;
      this.pram.cid = Number.parseInt(cid);
      this.pram.content = content;
      this.pram.imageInput = imageInput;
      this.pram.isBanner = isBanner;
      this.pram.isHot = isHot;
      this.pram.shareSynopsis = shareSynopsis;
      this.pram.shareTitle = shareTitle;
      this.pram.sort = sort;
      this.pram.synopsis = synopsis;
      this.pram.title = title;
      this.pram.url = url;
      this.pram.id = id;
      // this.pram.mediaId = mediaId
    },
    handlerGetCategoryTreeData: function handlerGetCategoryTreeData() {
      var _this3 = this;
      categoryApi.listCategroy({
        type: 3,
        status: ''
      }).then(function (data) {
        _this3.categoryTreeData = data.list;
        localStorage.setItem('articleClass', JSON.stringify(data.list));
      });
    },
    handerSubmit: (0, _validate.Debounce)(function (form) {
      var _this4 = this;
      this.$refs[form].validate(function (valid) {
        if (!valid) return;
        if (!_this4.$route.params.id) {
          _this4.handlerSave();
        } else {
          _this4.handlerUpdate();
        }
      });
    }),
    handlerUpdate: function handlerUpdate() {
      var _this5 = this;
      this.loading = true;
      this.pram.cid = Array.isArray(this.pram.cid) ? this.pram.cid[0] : this.pram.cid;
      this.pram.shareTitle = this.pram.title;
      this.pram.shareSynopsis = this.pram.synopsis;
      articleApi.UpdateArticle(this.pram).then(function (data) {
        _this5.$message.success('编辑文章成功');
        _this5.loading = false;
        _this5.$router.push({
          path: '/content/articleManager'
        });
      }).catch(function () {
        _this5.loading = false;
      });
    },
    handlerSave: function handlerSave() {
      var _this6 = this;
      this.loading = true;
      this.pram.cid = Array.isArray(this.pram.cid) ? this.pram.cid[0] : this.pram.cid;
      this.pram.shareTitle = this.pram.title;
      this.pram.shareSynopsis = this.pram.synopsis;
      articleApi.AddArticle(this.pram).then(function (data) {
        _this6.$message.success('新增文章成功');
        _this6.loading = false;
        _this6.$router.push({
          path: '/content/articleManager'
        });
      }).catch(function () {
        _this6.loading = false;
      });
    },
    setTagsViewTitle: function setTagsViewTitle() {
      var title = '编辑文章';
      var route = Object.assign({}, this.tempRoute, {
        title: "".concat(title, "-").concat(this.$route.params.id)
      });
      this.$store.dispatch('tagsView/updateVisitedView', route);
    }
  }
};