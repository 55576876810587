var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tinymce-container editor-container",
      class: { fullscreen: _vm.fullscreen },
    },
    [
      _c("textarea", {
        staticClass: "tinymce-textarea",
        attrs: { id: _vm.tinymceId },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "editor-custom-btn-container" },
        [
          _c("editorImage", {
            staticClass: "editor-upload-btn",
            attrs: { color: "#1890ff", "max-size": _vm.maxSize },
            on: { successCBK: _vm.imageSuccessCBK },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }