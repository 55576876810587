"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
var _index = _interopRequireDefault(require("@/components/cards/index"));
var _ZBParser = _interopRequireDefault(require("@/components/FormGenerator/components/parser/ZBParser"));
var _orderDetail = _interopRequireDefault(require("./orderDetail"));
var _orderSend = _interopRequireDefault(require("./orderSend"));
var _orderVideoSend = _interopRequireDefault(require("./orderVideoSend"));
var _storePoint = require("@/api/storePoint");
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _utils = require("@/utils");
var _store = require("@/api/store");
var _permission = require("@/utils/permission");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'orderlistDetails',
  components: {
    cardsData: _index.default,
    zbParser: _ZBParser.default,
    detailsFrom: _orderDetail.default,
    orderSend: _orderSend.default,
    orderVideoSend: _orderVideoSend.default
  },
  data: function data() {
    return {
      RefuseVisible: false,
      RefuseData: {},
      orderId: '',
      refundVisible: false,
      refundData: {},
      dialogVisibleJI: false,
      tableDataLog: {
        data: [],
        total: 0
      },
      tableFromLog: {
        page: 1,
        limit: 10,
        orderNo: 0
      },
      LogLoading: false,
      isCreate: 1,
      editData: null,
      dialogVisible: false,
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tableFrom: {
        status: 'all',
        dateLimit: '',
        orderNo: '',
        page: 1,
        limit: 10,
        type: 0
      },
      orderChartType: {},
      timeVal: [],
      fromList: this.$constants.fromList,
      fromType: [{
        value: 'all',
        text: '全部'
      }, {
        value: 'info',
        text: '普通'
      }, {
        value: 'pintuan',
        text: '拼团'
      }, {
        value: 'bragin',
        text: '砍价'
      }, {
        value: 'miaosha',
        text: '秒杀'
      }],
      selectionList: [],
      ids: '',
      orderids: '',
      cardLists: [],
      isWriteOff: (0, _utils.isWriteOff)(),
      proType: 0,
      active: false
    };
  },
  mounted: function mounted() {
    this.getList();
    this.getOrderStatusNum();
    // this.getOrderListData();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    resetFormRefundhandler: function resetFormRefundhandler() {
      this.refundVisible = false;
    },
    resetFormRefusehand: function resetFormRefusehand() {
      this.RefuseVisible = false;
    },
    resetForm: function resetForm(formValue) {
      this.dialogVisible = false;
    },
    // 核销订单
    onWriteOff: function onWriteOff(row) {
      var _this = this;
      this.$modalSure('核销订单吗').then(function () {
        (0, _order.writeUpdateApi)(row.verifyCode).then(function () {
          _this.$message.success('核销成功');
          _this.tableFrom.page = 1;
          _this.getList();
        });
      });
    },
    seachList: function seachList() {
      this.tableFrom.page = 1;
      this.getList();
      this.getOrderStatusNum();
    },
    // 拒绝退款
    RefusehandleClose: function RefusehandleClose() {
      this.RefuseVisible = false;
    },
    onOrderRefuse: function onOrderRefuse(row) {
      this.orderids = row.orderId;
      this.RefuseData = {
        orderId: row.orderId,
        reason: ''
      };
      this.RefuseVisible = true;
    },
    RefusehandlerSubmit: function RefusehandlerSubmit(formValue) {
      var _this2 = this;
      (0, _order.orderRefuseApi)({
        orderNo: this.orderids,
        reason: formValue.reason
      }).then(function (data) {
        _this2.$message.success('操作成功');
        _this2.RefuseVisible = false;
        _this2.getList();
      });
    },
    // 立即退款
    refundhandleClose: function refundhandleClose() {
      this.refundVisible = false;
    },
    onOrderRefund: function onOrderRefund(row) {
      this.refundData = {
        orderId: row.orderId,
        amount: row.payPrice,
        type: ''
      };
      this.orderids = row.orderId;
      this.refundVisible = true;
    },
    refundhandlerSubmit: function refundhandlerSubmit(formValue) {
      var _this3 = this;
      (0, _order.orderRefundApi)({
        amount: formValue.amount,
        orderNo: this.orderids
      }).then(function (data) {
        _this3.$message.success('操作成功');
        _this3.refundVisible = false;
        _this3.getList();
      });
    },
    // 发送
    sendOrder: function sendOrder(row) {
      if (row.type === 0) {
        this.$refs.send.modals = true;
        this.$refs.send.getList();
        this.$refs.send.sheetInfo();
      } else {
        this.$refs.videoSend.modals = true;
        if (!JSON.parse(sessionStorage.getItem('videoExpress'))) this.$refs.videoSend.companyGetList();
      }
      this.orderId = row.orderId;
    },
    // 订单删除
    handleDelete: function handleDelete(row, idx) {
      var _this4 = this;
      if (row.isDel) {
        this.$modalSure().then(function () {
          (0, _order.orderDeleteApi)({
            orderNo: row.orderId
          }).then(function () {
            _this4.$message.success('删除成功');
            _this4.tableData.data.splice(idx, 1);
          });
        });
      } else {
        this.$confirm('您选择的的订单存在用户未删除的订单，无法删除用户未删除的订单！', '提示', {
          confirmButtonText: '确定',
          type: 'error'
        });
      }
    },
    // 详情
    onOrderDetails: function onOrderDetails(id) {
      this.orderId = id;
      this.$refs.orderDetail.getDetail(id);
      this.$refs.orderDetail.dialogVisible = true;
    },
    // 订单记录
    onOrderLog: function onOrderLog(id) {
      var _this5 = this;
      this.dialogVisibleJI = true;
      this.LogLoading = true;
      this.tableFromLog.orderNo = id;
      (0, _order.orderLogApi)(this.tableFromLog).then(function (res) {
        _this5.tableDataLog.data = res.list;
        _this5.tableDataLog.total = res.total;
        _this5.LogLoading = false;
      }).catch(function () {
        _this5.LogLoading = false;
      });
    },
    pageChangeLog: function pageChangeLog(page) {
      this.tableFromLog.page = page;
      this.onOrderLog();
    },
    handleSizeChangeLog: function handleSizeChangeLog(val) {
      this.tableFromLog.limit = val;
      this.onOrderLog();
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    // 备注
    onOrderMark: function onOrderMark(row) {
      var _this6 = this;
      this.$prompt('订单备注', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputErrorMessage: '请输入订单备注',
        inputType: 'textarea',
        inputValue: row.remark,
        inputPlaceholder: '请输入订单备注',
        inputValidator: function inputValidator(value) {
          if (!value) return '输入不能为空';
        }
      }).then(function (_ref) {
        var value = _ref.value;
        (0, _order.orderMarkApi)({
          mark: value,
          orderNo: row.orderId
        }).then(function () {
          _this6.$message.success('操作成功');
          _this6.getList();
        });
      }).catch(function () {
        _this6.$message.info('取消输入');
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.selectionList = val;
      var data = [];
      this.selectionList.map(function (item) {
        data.push(item.orderId);
      });
      this.ids = data.join(',');
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.timeVal = [];
      this.tableFrom.page = 1;
      this.getList();
      this.getOrderStatusNum();
      // this.getOrderListData();
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.dateLimit = e ? this.timeVal.join(',') : '';
      this.tableFrom.page = 1;
      this.getList();
      this.getOrderStatusNum();
      // this.getOrderListData();
    },
    // 编辑
    edit: function edit(row) {
      this.orderId = row.orderId;
      this.editData = {
        orderId: row.orderId,
        totalPrice: row.totalPrice,
        totalPostage: row.totalPostage,
        payPrice: row.payPrice,
        payPostage: row.payPostage,
        gainIntegral: row.gainIntegral
      };
      this.dialogVisible = true;
    },
    handlerSubmit: function handlerSubmit(formValue) {
      var _this7 = this;
      var data = {
        orderNo: formValue.orderId,
        payPrice: formValue.payPrice
      };
      (0, _order.updatePriceApi)(data).then(function (data) {
        _this7.$message.success('编辑数据成功');
        _this7.dialogVisible = false;
        _this7.getList();
      });
    },
    // 列表
    getList: function getList() {
      var _this8 = this;
      this.listLoading = true;
      (0, _order.orderListApi)(this.tableFrom).then(function (res) {
        _this8.tableData.data = res.list || [];
        _this8.tableData.total = res.total;
        _this8.listLoading = false;
        _this8.checkedCities = _this8.$cache.local.has('order_stroge') ? _this8.$cache.local.getJSON('order_stroge') : _this8.checkedCities;
      }).catch(function () {
        _this8.listLoading = false;
      });
    },
    // 数据统计
    getOrderListData: function getOrderListData() {
      var _this9 = this;
      (0, _order.orderListDataApi)({
        dateLimit: this.tableFrom.dateLimit
      }).then(function (res) {
        _this9.cardLists = [{
          name: '订单数量',
          count: res.count,
          color: '#1890FF',
          class: 'one',
          icon: 'icondingdan'
        }, {
          name: '订单金额',
          count: res.amount,
          color: '#A277FF',
          class: 'two',
          icon: 'icondingdanjine'
        }, {
          name: '微信支付金额',
          count: res.weChatAmount,
          color: '#EF9C20',
          class: 'three',
          icon: 'iconweixinzhifujine'
        }, {
          name: '余额支付金额',
          count: res.yueAmount,
          color: '#1BBE6B',
          class: 'four',
          icon: 'iconyuezhifujine2'
        }];
      });
    },
    // 获取各状态数量
    getOrderStatusNum: function getOrderStatusNum() {
      var _this10 = this;
      (0, _order.orderStatusNumApi)({
        dateLimit: this.tableFrom.dateLimit,
        type: this.tableFrom.type
      }).then(function (res) {
        _this10.orderChartType = res;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    exports: function exports() {
      var data = {
        dateLimit: this.tableFrom.dateLimit,
        orderNo: this.tableFrom.orderNo,
        status: this.tableFrom.status,
        type: this.tableFrom.type
      };
      (0, _store.orderExcelApi)(data).then(function (res) {
        window.open(res.fileName);
      });
    },
    //打印小票
    onOrderPrint: function onOrderPrint(data) {
      var _this11 = this;
      (0, _order.orderPrint)(data.orderId).then(function (res) {
        _this11.$modal.msgSuccess('打印成功');
      }).catch(function (error) {
        _this11.$modal.msgError(error.message);
      });
    }
  }
};